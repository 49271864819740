<template>
  <ul
    role="tablist"
    class="radio-switcher"
    :aria-label="ariaLabel"
    :data-testid="dataTestid"

  >
    <li
      v-for="(option, i) in options"
      :key="i"
      :class="{
        'radio-switcher__item': true,
        'radio-switcher__item-checked': values[i] === inputValue
      }"
      role="presentation">
      <a
        v-if="values[i] === inputValue"
        :dataTestid="option"
        class="radio-switcher__label"
        role="tab"
        aria-selected="true"
        @click="optionClicked(i)"
        tabindex="0"
        @keydown.prevent.enter.space="optionClicked(i)">
        {{ $t(option) }}
      </a>
      <a
        v-else
        aria-selected="false"
        :dataTestid="option"
        class="radio-switcher__label"
        role="tab"
        @click="optionClicked(i)"
        tabindex="-1"
        @keydown.prevent.enter.space="optionClicked(i)">
        {{ $t(option) }}
      </a>
    </li>
  </ul>
</template>

<script>
  import testid from '@/mixins/testid/testid'

  export default {
    mixins: [
      testid('radio-switcher'),
    ],

    props: {
      ariaLabel: {
        type: String,
        default: undefined,
      },
      options: {
        type: Array,
        required: true,
      },
      value: {
        type: [String, Boolean],
        required: true,
      },
      values: {
        type: Array,
        required: true,
      },
    },

    mounted() {
      [this.inputValue] = this.values
    },

    watch: {
      inputValue() {
        this.$emit('input', this.inputValue)
      },
    },

    data() {
      return {
        inputValue: '',
      }
    },

    methods: {
      optionClicked(i) {
        this.inputValue = this.values[i]
      },
    },
  }
</script>

<style lang="scss">
  $block: 'radio-switcher';

  .radio-switcher {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: solid 1px color(primary);
    height: auto;
    &__item {
      background-color: var(--color-light-grey);
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      min-height: 3rem;
      padding: 0.25rem 2rem;
      display: flex;
      align-items: center;
      height: auto;
      position: relative;
      @media (max-width: 400px) {
        padding: 0.25rem 1rem;
      }
    }
    &__item-checked {
      background-color: color(_white);
      border: solid 1px color(primary);
      border-bottom: 0;
      margin-right: 0.5rem;
      margin-bottom: -1px !important;
      min-height: 4.1rem;
      margin-top: -5px;
      height: auto;
    }
    :not(.radio-switcher__item-checked) > a {
      border-bottom: solid 1px black;
      &:focus {
        outline: none;
        background-color: #fd0;
        border-bottom: solid 3px black;
      }
      &:hover {
        border-bottom: solid 3px black;
      }
    }
    &__label {
      font-size: 1.4rem;
      line-height: 1.4rem;
      min-height: 1.7rem;
      cursor: pointer;
      height: auto;
      font-weight: 500;
    }
  }
  .is-tab {
    .#{$block}__input:focus + .#{$block}__label {
        box-shadow: 0 0 0 5px color(accent);
    }
  }
</style>
