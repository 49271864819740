<template>
  <base-main
    class="weight-tracking"
    data-testid="weight-tracking"
    :loading="$options.loading"
  >

    <container first>
      <div class="weight-tracking__layout">
        <tracking-goals
          :addButtonText="i18n('add-button')"
          :editButtonText="i18n('edit-button')"
          :goalTypeId="$static.goalCategory"
          :imageUrl="requireImage('modular-tile/top-tracking-goal.jpg')"
        />

        <tracking-dashboard
          :headerText="i18n('header')"
          data-testid="weight-tracking-dashboard"
          v-bind="{ isFetchingChartData }"
        >
          <template #log-button>
            <base-button
              no-margin
              data-testid="weight-tracking-open-log-dialog"
              @click="clickLog(type)"
            >
              {{ $t('Log weight') }}
            </base-button>
          </template>

          <template #tracking-datepicker>
            <tracking-datepicker
              read-only
              v-bind="{startDate, endDate, period}"
              v-model="endDate"
              @visibleDateRangeChange="onVisibleDateRangeChange"
            />
          </template>

          <template #radio-switcher>
            <radio-switcher
              :ariaLabel="$t('Chart period view')"
              :options="$static.periodOptions"
              :values="$static.periodValues"
              v-model="period"
            />
            <div class="weight-tracking__today tracking__today">
              <base-button
                bordered
                no-margin
                small
                data-testid="weight-tracking-set-today"
                @click="setToday"
              >
                {{ $t(todayText) }}
              </base-button>
            </div>
          </template>

          <template #chart>
            <div
              v-if="chartDataFormat"
              class="weight-tracking__chart"
              role="img"
              :aria-label="chartAriaLabel"
            >
              <plugin-unit-chart
                :unit="unitAbbrPluginChart"
              >
                <plugin-line-chart
                  :goalPadding="convert(30)"
                  :onClick="onChartClick"
                  :suggestedMax="convert(100)"
                  :suggestedMin="convert(40)"
                  :chartData="chartDataFormat"
                  v-bind="{ annotationLabel, goal, period }"
                />
              </plugin-unit-chart>
            </div>
          </template>

          <template #chart-details>
            <div
              class="weight-tracking__details"
              data-testid="tracking-details-list"
            >
              <tracking-details-tile
                :columns="detailsColumns"
                :values="detailsValues"
              />
            </div>
          </template>
          <template #chart-accessibility>
            <tracking-details-table
              v-if="loggedWeightValues && hasLoggedWeightValues"
              :name="i18n('table-name')"
              :headings="detailsColumns"
              :hasEditButton="period !== 'year'"
              :items="loggedWeightValues"
              @edit="(key) => editWeight(key)"
              @delete="(key) => deleteWeight(key)"
            />
          </template>
        </tracking-dashboard>
      </div>
    </container>
  </base-main>
</template>

<script>
  import moment from 'moment'
  import { mapGetters, mapMutations, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import {
    PluginLineChart,
    PluginUnitChart,
  } from '@/components/plugin/plugin-charts'
  import RadioSwitcher from '@/components/radio-switcher'
  import {
    TrackingDashboard,
    TrackingDatepicker,
    TrackingDetailsTable,
    TrackingDetailsTile,
    TrackingGoals,
  } from '@/components/tracking'

  import {
    DATE_FORMATS,
    GOAL_CATEGORIES,
    UNIT_CONVERTERS,
  } from '@/constants'

  import capitalize from '@/filters/capitalize'

  import getCssColorVariable from '@/helpers/getCssColorVariable'

  import i18n from '@/mixins/i18n'
  import requireImage from '@/mixins/requireImage'
  import {
    trackingBase,
    trackingChart,
    trackingGoal,
    trackingSettings,
  } from '@/mixins/tracking'
  import unblockPlugin from '@/mixins/unblockPlugin'
  import weightUnitConverter from '@/mixins/weight-unit-converter/weightUnitConverter'

  import eventBus from '@/tools/event-bus'

  const module = 'weightTracking'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.weight-tracking.weight-tracking.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginLineChart,
      PluginUnitChart,
      RadioSwitcher,
      TrackingDashboard,
      TrackingDatepicker,
      TrackingDetailsTable,
      TrackingDetailsTile,
      TrackingGoals,
    },

    mixins: [
      i18n,
      requireImage,
      trackingBase,
      trackingChart,
      trackingGoal,
      trackingSettings,
      unblockPlugin,
      weightUnitConverter,
    ],

    props: {
      logWeight: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    created() {
      this.unblockPlugin()
    },

    watch: {
      week: 'refreshChartData',
      month: 'refreshChartData',
      year: 'refreshChartData',
    },

    methods: {
      ...mapMutations(module, [
        'setVisibleDateRange',
      ]),
      onFetchTimePeriod() {
        eventBus.$emit(this.$static.refreshChartEvent)
        this.setToday()

        this.isFirstFetch = false
      },
      highlightDatapoint(index) {
        const [dataset] = this.chartData.data.datasets
        const colorPrimary = getCssColorVariable('primary')
        const colorDarkPrimary = getCssColorVariable('dark-primary')
        const colors = dataset.pointBackgroundColor
          .map((val, i) => (i === index ? colorPrimary : colorDarkPrimary))

        dataset.pointBackgroundColor = colors
        dataset.pointHoverBackgroundColor = colors
        eventBus.$emit(this.$static.refreshChartEvent)
      },
      getFormatedDate(date) {
        return moment(date)
          .format(this.period === 'year'
            ? DATE_FORMATS.monthYear
            : DATE_FORMATS.date)
      },
      getWeightValue(weight) {
        if (this.isStonesAndPounds) {
          if (!weight.stones && !weight.pounds) {
            return this.i18n('no-log')
          }
          return `${weight.stones} ${this.unitAbbr[0]} ${weight.pounds} ${this.unitAbbr[1]}`
        }
        if (!weight?.value) {
          return this.i18n('no-log')
        }

        return `${weight.value} ${this.unitAbbr}`
      },
      convert(value) {
        return this.isStonesAndPounds
          ? this.convertToStonesOrPoundsIfNeeded(value).stones
          : this.convertToStonesOrPoundsIfNeeded(value).value
      },
      editWeight(key) {
        this.$router.push({ name: 'WeightTrackingLog', params: { id: `${moment(key).format('YYYY-MM-D')}` } })
      },
      deleteWeight(key) {
        this.$router.push({ name: 'WeightTrackingDelete', params: { id: `${moment(key).format('YYYY-MM-D')}` } })
      },
      clickLog(type) {
        this.$router.push({ name: 'WeightTrackingLog', params: { type } })
      },
    },

    computed: {
      ...mapState(module, [
        'week',
        'month',
        'year',
        'visibleDateRange',
      ]),
      ...mapGetters('user', [
        'getCurrentUserWeightUnit',
        'isStonesAndPounds',
      ]),
      type() {
        return capitalize(this.period)
      },
      unitAbbr() {
        if (!this.settings) return

        return this.getCurrentUserWeightUnit.abbr
      },
      goal() {
        if (!this.goalContent) return

        const valueConverted = this.convertToStonesOrPoundsIfNeeded(parseFloat(this.goalContent))

        if (this.isStonesAndPounds) {
          return Number(
            (valueConverted.stones + valueConverted.pounds / UNIT_CONVERTERS.poundsInStones)
            .toFixed(1),
          )
        }

        return valueConverted.value
      },
      weightWithUnit() {
        if (!this.goal || !this.unitAbbr) return

        return `${this.goal} ${this.unitAbbr}`
      },
      detailsDataLabel() {
        return this.period === 'year'
          ? this.i18n('details-label-1')
          : this.i18n('details-label-2')
      },
      detailsDataValue() {
        return this.getWeightValue(this.selectedDatapoint.value)
      },
      detailsColumns() {
        if (!this.selectedDatapoint) return

        return ['Selected date', this.$t(this.detailsDataLabel)]
      },
      detailsValues() {
        if (!this.selectedDatapoint) return

        const dateText = this.getFormatedDate(this.selectedDatapoint?.date)

        return [dateText, this.detailsDataValue]
      },
      annotationLabel() {
        return this.weightWithUnit
          ? this.i18n('goal-label', { weightGoal: this.weightWithUnit })
          : undefined
      },
      loggedWeightValues() {
        const x = this.chartData.data.labels.map((label, index) => {
          const value = this.chartData.data.datasets[0].data[index]
          if (this.isStonesAndPounds && !value.stones && !value.pounds) return { columns: [] }
          if (!this.isStonesAndPounds && !value.value) return { columns: [] }
          return { columns: [
            this.getFormatedDate(label),
            this.getWeightValue(value),
          ] }
        })
        return x
      },
      hasLoggedWeightValues() {
        return this.loggedWeightValues.findIndex(({ columns }) => columns.length !== 0) !== -1
      },
      chartAriaLabel() {
        return `${this.i18n('table-name')} ${this.visibleDateRange || ''}`
      },
      unitAbbrPluginChart() {
        return this.isStonesAndPounds ? this.unitAbbr[0] : this.unitAbbr
      },
      chartDataFormat() {
        const dataCloned = JSON.parse(JSON.stringify(this.chartData))
        const datasets = dataCloned.data.datasets[0]
        const data = []
        dataCloned.data.datasets[0].data
          .forEach(value => {
            let val = this.isStonesAndPounds ? value.stones : value.value
            if (this.isStonesAndPounds) {
              val = value.stones + value.pounds / UNIT_CONVERTERS.poundsInStones
            } else {
              val = value.value
            }
            data.push(val === 0 ? null : val)
          })

        datasets.data = data

        return dataCloned
      },
    },

    slug: 'views.plugins.weight-tracking.weight-tracking',

    loading: [
      'goals/fetchGoals',
      'goals/fetchPlugin',
      'weightTracking/getSettings',
      'weightTracking/getTimePeriod',
    ],

    static() {
      return {
        module,
        goalCategory: GOAL_CATEGORIES.weightTracking,
        goalTemplateSlug: 'weight_goal',
        // logDialog: 'WeightTrackingLog',
        refreshChartEvent: 'lineChart/refreshChart',
        settingsComponent: 'WeightTrackingStart',
        skipZeroes: true,
      }
    },
  }
</script>

<style lang="scss">
  $weightTrackingBlock: 'weight-tracking';

  .#{$weightTrackingBlock} {
    max-width: none;
    &__layout {
      @include min-lg {
        display: grid;
        grid-column-gap: 3rem;
        grid-template-columns: 27rem 1fr;
      }
      & > .simple-tile__main {
        @include min-lg {
          padding: 3.5rem;
        }
      }
    }
    &__goals {
      @include min-lg {
        width: 27rem;
        margin: 0 3rem 0 0;
      }
    }
    &__goal {
      margin: 0 0 2rem;
      .modular-tile {
        &__main {
          @include max-lg {
            padding: 2rem;
          }
        }
        &__header-row {
          @include max-lg {
            align-items: flex-start;
            min-height: 3rem;
          }
        }
        &__top-image-row {
          @include max-lg {
            display: none;
          }
        }
      }
      .button {
        margin: 1rem 0 0;
        @include min-lg {
          margin: 0;
          padding: 2px 1.8rem 0;
        }
      }
    }
    &__arrow-button {
      width: 2.4rem;
      height: 2.4rem;
      @include min-lg {
        width: 3.4rem;
        height: 3.4rem;
      }
    }
    &__arrow {
      grid-area: button-prev;
      width: 1.6rem;
      height: auto;
      &--right {
        grid-area: button-next;
        transform: rotate(180deg);
      }
    }
    &__today {
      display: none;
      text-align: right;
      @include min-lg {
        display: block;
      }
    }
    &__tracking {
      padding: 2rem 0 0;
      font-size: 1.2rem;
      text-align: center;
      @include min-lg {
        padding: 0;
        text-align: right;
      }
    }
    &__tracking-label {
      font-weight: 600;
      text-transform: uppercase;
    }
    &__tracking-button {
      padding: 0;
      color: color(dark-grey);
      font-weight: 400;
      text-decoration: underline;
    }
    &__chart {
      display: block;
      border: solid 1px color(primary);
      border-top: 0;
    }
    &__details {
      margin: 3rem 0 0;
    }
    .container {
      @include max-sm {
        padding: 1rem;
      }
      @include min-lg {
        width: 120rem;
      }
    }
    .tracking-dashboard {
      &__secondary-actions {
        justify-content: flex-end;
      }
    }
    .plugin-unit-chart {
      &__unit-layout {
        margin: 2rem 0 0;
      }
    }
  }
</style>
