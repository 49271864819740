<template>
  <div
    ref="videoPlayerWrapper"
    class="video-player"
    data-testid="video-player"
  >
    <radio-switcher
      v-if="hasAlternativeAudio"
      class="radio-switcher"
      :options="options"
      :values="values"
      v-model="isVideoWithAlternativeAudio"
    />
    <!-- eslint-disable vuejs-accessibility/media-has-caption -->
    <video
      ref="videoPlayer"
      class="video-js"
    />
    <!-- eslint-enable vuejs-accessibility/media-has-caption -->
  </div>
</template>

<script>
  import videojs from 'video.js'
  import { mapState } from 'vuex'

  import RadioSwitcher from '@/components/radio-switcher'
  import { ALLOWED_KEYS } from '@/constants'

  import i18n from '@/mixins/i18n'
  import eventBus from '@/tools/event-bus'

  const replaceElement = element => {
    const clone = element.cloneNode(true)
    element.parentNode.replaceChild(clone, element)

    return clone
  }

  export default {
    components: {
      RadioSwitcher,
    },

    mixins: [
      i18n,
    ],

    props: {
      item: {
        type: Object,
        required: true,
      },
      captions: {
        type: String,
        required: false,
        default: '',
      },
      alternateCaptions: {
        type: String,
        required: false,
        default: '',
      },
    },

    mounted() {
      this.init(this.os)
    },

    beforeDestroy() {
      this.toggleEventListeners('remove')

      if (this.player) {
        this.player.dispose()
      }

      eventBus.$off('videoPlayerPlaying', this.onOtherPlayerPlay)
    },

    watch: {
      captions: {
        handler: 'addCaptions',
        immediate: true,
      },
      os: {
        handler: 'init',
        immediate: true,
      },
      videoUrl: {
        handler(src) {
          if (!this.player) {
            this.init(this.os)
            return
          }

          const videoCurrentTime = this.player.currentTime()
          const isPaused = this.player.paused()

          this.player.src({ type: 'video/mp4', src })
          this.player.on('loadedmetadata', () => {
            this.player.currentTime(videoCurrentTime)

            if (!isPaused) {
              this.player.play()
            }

            if (this.isVideoWithAlternativeAudio) {
              this.addCaptions(this.alternateCaptions || this.captions)
            } else {
              this.addCaptions(this.captions)
            }
          })
        },
      },
    },

    data() {
      return {
        areTextTracksNative: false,
        captionsSize: 40,
        isRateMenuOpened: false,
        isVideoWithAlternativeAudio: false,
        isSwiping: false,
        player: null,
        options: ['Video without audio description', 'Video with audio description'],
        values: [false, true],
        isCaptionsVisible: false,
      }
    },

    methods: {
      init(val) {
        if (!val || !this.$refs.videoPlayer || !this.item.video) return

        this.areTextTracksNative = val === 'ios'

        this.player = videojs(this.$refs.videoPlayer, this.playerOptions)

        this.player.on('ready', () => {
          this.player.vhs = null
          this.toggleEventListeners('add')
          this.addAria()
          this.changeRateButtonBehaviour()
          this.changeCaptionsButtonBehaviour()
          this.addCaptions(this.captions)
        })
        this.player.one('play', this.onFirstPlay)
        this.player.on('play', this.onPlay)
        this.player.remoteTextTracks().addEventListener('selectedlanguagechange', () => {
          const track = this.player.textTracks()?.[0]
          if (track?.mode === 'showing') {
            this.isCaptionsVisible = true
          } else {
            this.isCaptionsVisible = false
          }
        })
        eventBus.$on('videoPlayerPlaying', this.onOtherPlayerPlay)
      },
      toggleEventListeners(action) {
        const events = [
          'TouchStart',
          'TouchMove',
          'TouchEnd',
        ]

        events.forEach(event => (
          this.$refs.videoPlayerWrapper[`${action}EventListener`](event.toLowerCase(), this[`on${event}Player`])
        ))
      },
      onTouchStartPlayer() {
        this.isSwiping = false
      },
      onTouchMovePlayer() {
        this.isSwiping = true
      },
      onTouchEndPlayer(e) {
        if (!this.isSwiping) {
          this.togglePlayer(e)
        }
      },
      togglePlayer({ target }) {
        if (target != this.$refs.videoPlayerWrapper.querySelector('video') || !this.player) return

        this.player.paused()
          ? this.player.play()
          : this.player.pause()
      },
      async toggleRateMenuAndFocus(e) {
        const {
          enter,
          space,
        } = ALLOWED_KEYS

        if (![enter, space].includes(e.keyCode)) return

        this.toggleRateMenu()

        await this.$nextTick()

        e.target.parentElement
          ?.querySelector('.vjs-menu-item.vjs-selected')
          .focus()
      },
      toggleRateMenu() {
        this.isRateMenuOpened = !this.isRateMenuOpened

        this.$refs.videoPlayerWrapper.querySelector('.vjs-menu-button-popup .vjs-menu')
          .classList.toggle('vjs-menu__visible')

        this.$refs.videoPlayerWrapper
          .querySelector('.vjs-playback-rate')
          .setAttribute('aria-expanded', this.isRateMenuOpened)
      },
      addAria() {
        const playbackRateMenu = this.$refs.videoPlayerWrapper?.querySelector('.vjs-playback-rate .vjs-menu-content')

        if (!playbackRateMenu) return

        const rateButtons = Array.from(playbackRateMenu.querySelectorAll('.vjs-playback-rate .vjs-menu-item'))

        rateButtons
          .forEach(el => {
            const speedText = el.querySelector('.vjs-menu-item-text')?.innerText

            el.setAttribute('aria-label', `${speedText} speed`)
            el.setAttribute('tabindex', '0')
            el.setAttribute('role', 'radio')
            el.querySelector('.vjs-control-text').remove()
            this.addBlurBehaviour(el)

            const controlText = el.querySelector('.vjs-control-text')

            if (controlText) {
              controlText.remove()
            }
          })
      },
      addBlurBehaviour(el) {
        const onBlur = () => {
          const timeout = setTimeout(() => {
            if (!document.activeElement.classList.contains('vjs-menu-item')) {
              this.toggleRateMenu()
            }
          })

          this.$once('hook:beforeDestroy', () => clearTimeout(timeout))
        }

        el.addEventListener('blur', onBlur)

        this.$once('hook:beforeDestroy', () => {
          el.removeEventListener('blur', onBlur)
        })
      },
      changeCaptionsButtonBehaviour() {
        const captionsButton = this.$refs.videoPlayerWrapper.querySelector('button.vjs-subs-caps-button')
        const toggleCaptionsMenu = () => setTimeout(() => captionsButton.focus())

        captionsButton.addEventListener('touchstart', toggleCaptionsMenu)

        this.$once('hook:beforeDestroy', () => {
          captionsButton.removeEventListener('touchstart', toggleCaptionsMenu)
        })
      },
      changeRateButtonBehaviour() {
        const playbackRateValue = this.$refs.videoPlayerWrapper.querySelector('.vjs-playback-rate-value')
        const rateButton = replaceElement(this.$refs.videoPlayerWrapper.querySelector('.vjs-playback-rate button'))
        const playbackRateValueText = playbackRateValue.innerText

        playbackRateValue.setAttribute('aria-hidden', 'true')
        rateButton.setAttribute('aria-label', `${playbackRateValueText} speed`)

        rateButton.addEventListener('mousedown', this.toggleRateMenu)
        rateButton.addEventListener('keydown', this.toggleRateMenuAndFocus)

        this.$once('hook:beforeDestroy', () => {
          rateButton.removeEventListener('mousedown', this.toggleRateMenu)
          rateButton.removeEventListener('keydown', this.toggleRateMenuAndFocus)
        })
      },
      addCaptions(captionsUrl) {
        if (!captionsUrl || !this.player) return

        const tracks = this.player.remoteTextTracks()
        if (tracks.length === 0) {
          this.player.addRemoteTextTrack({
            label: this.item.language,
            kind: 'captions',
            srclang: this.item.language,
            src: captionsUrl,
            default: true,
            mode: this.isCaptionsVisible ? 'showing' : 'disabled',
          }, false)
        } else {
          const track = tracks[0]
          track.mode = this.isCaptionsVisible ? 'showing' : 'disabled'
        }
      },
      onFirstPlay() {
      // Set captions off by default for all consumer app videos
      // if (['hidden', 'disabled'].includes(this.player.textTracks()?.[0]?.mode)) {
      //   this.player.textTracks()[0].mode = 'showing'
      // }
      },
      onPlay() {
        eventBus.$emit('videoPlayerPlaying', this.player)
      },
      onOtherPlayerPlay(player) {
        if (this.player && player !== this.player && !this.player.paused()) {
          this.player.pause()
        }
      },
      getVideo(video) {
        return video?.url ?? video
      },
      toggleVideoAudioTrack() {
        this.isVideoWithAlternativeAudio = !this.isVideoWithAlternativeAudio
      },
    },

    computed: {
      ...mapState('layout', [
        'os',
      ]),
      playerOptions() {
        return {
          language: 'en',
          controls: true,
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: 'video/mp4',
            src: this.videoUrl,
          }],
          html5: {
            areTextTracksNative: this.areTextTracksNative,
          },
        }
      },
      videoUrl() {
        if (!this.item) return null

        return this.isVideoWithAlternativeAudio
          ? this.getVideo(this.item.alternative_video)
          : this.getVideo(this.item.video)
      },
      hasAlternativeAudio() {
        return !!this.item.alternative_video
      },
    },

    slug: 'component.video-player',
  }
</script>

<style lang="scss">
  @import '~video.js/dist/video-js.css';

  .rate-btn {
    position: absolute;
    top: 0;
    left: 0;
  }

  .video-player {
    width: 100%;
    height: 100%;
    .radio-switcher {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: solid 1px var(--color-dark-primary);
      .radio-switcher__item-checked {
        border-color: var(--color-dark-primary);
      }
      & > li {
        &::before {
          content: '' !important;
        }
        a {
          text-decoration: none;
        }
      }
    }
    &__video-toggle {
      text-transform: none;
      margin-bottom: 1.5rem;
      .button-text {
        font-weight: 600;
      }
    }
    .vjs-big-play-button {
      width: 5rem;
      height: 5rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
    .vjs-icon-play::before,
    .video-js .vjs-big-play-button .vjs-icon-placeholder::before,
    .video-js .vjs-play-control .vjs-icon-placeholder::before {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .vjs-menu__visible {
      display: block;
    }
    .vjs-picture-in-picture-control {
      display: none;
    }
    ::cue {
      font-size: 2rem;
    }
  }

  body.is-tab {
    .video-player {
      .vjs-slider {
        &:focus {
          .vjs-play-progress::before,
          .vjs-volume-level::before {
            outline: 4px solid var(--color-accent);
            outline-offset: 1px;
          }
        }
      }
      .vjs-control-bar button:focus {
        outline: 5px solid var(--color-accent);
        outline-offset: -5px;
        box-shadow: none;
      }
      .vjs-menu-item {
        position: relative;
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: none;
        }
        &:focus {
          box-shadow: none !important;
          &::before {
            outline: 4px solid var(--color-accent);
            outline-offset: -4px;
          }
        }
      }
    }
  }
</style>
