import { mapActions, mapGetters } from 'vuex'

const trackingBase = {
  beforeDestroy() {
    this.closeDialog()
  },

  methods: {
    ...mapActions('dialog', [
      'closeDialog',
      'openDialog',
    ]),
    openLogDialog() {
      if (!this.$static.logDialog) return

      this.openDialog({ component: this.$static.logDialog })
    },
    goToSettings() {
      if (!this.$static.settingsComponent) return

      this.$router.push({ name: this.$static.settingsComponent })
    },
  },

  computed: {
    ...mapGetters('loading', [
      'getLoadingStatesForActions',
      'isPackageOrProgramOrUserLoading',
    ]),
  },

  loading: [
    'program/fetchProgram',
  ],

  static() {
    return {
      settingsComponent: '',
      logDialog: '',
    }
  },
}

export default trackingBase
