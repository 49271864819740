import { mapActions, mapGetters } from 'vuex'

const trackingGoal = {
  data() {
    return {
      isLoadingFirstTime: true,
    }
  },

  watch: {
    isPackageOrProgramOrUserLoading: {
      handler() {
        if (this.isPackageOrProgramOrUserLoading) return

        const triggerDataId = this.getTriggerDataIdByPluginName('goal')

        Promise
          .all([
            this.fetchPlugin({
              triggerDataId,
              userId: this.getCurrentUserId,
            }),
            this.fetchGoals({
              programId: this.currentProgramId,
              userId: this.getCurrentUserId,
            }),
          ])
          .then(() => this.isLoadingFirstTime = false)
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('goals', [
      'fetchGoals',
      'fetchPlugin',
    ]),
  },

  computed: {
    ...mapGetters('goals', [
      'getGoalByProfileId',
      'getProfilesByGoalTypeId',
    ]),
    ...mapGetters('program', [
      'currentProgramId',
    ]),
    ...mapGetters('triggers', [
      'getTriggerDataIdByPluginName',
    ]),
    ...mapGetters('user', [
      'getCurrentUserId',
    ]),
    hasGoals() {
      if (!this.$static.goalCategory) return

      return !!this.getProfilesByGoalTypeId(this.$static.goalCategory).length
    },
    goalContent() {
      if (!this.$static.goalCategory || !this.$static.goalTemplateSlug) return

      const profile = this.getProfilesByGoalTypeId(this.$static.goalCategory)
        .find(item => item.template_slug == this.$static.goalTemplateSlug)

      if (!profile) return null

      const content = this.getGoalByProfileId(profile.id)?.answers[0]?.content

      return content?.[0] || ''
    },
  },

  loading: [
    'goals/fetchGoals',
    'goals/fetchPlugin',
  ],

  static() {
    return {
      goalCategory: null,
      goalTemplateSlug: '',
    }
  },
}

export default trackingGoal
