import moment from 'moment'
import { mapGetters } from 'vuex'

import capitalize from '@/filters/capitalize'

import eventBus from '@/tools/event-bus'

const trackingChart = {
  watch: {
    period: {
      handler() {
        this.fetchData()
      },
      immediate: true,
    },
  },

  data() {
    return {
      chartData: null,
      period: 'week',
      selectedDatapoint: null,
      selectedDatapointIndex: null,
      selectedDatasetIndex: 0,
    }
  },

  created() {
    this.toggleRefreshEvent('on')
  },

  beforeDestroy() {
    this.toggleRefreshEvent('off')
  },

  methods: {
    fetchData() {
      if (!this.$static.module) return

      this.$store.dispatch(`${this.$static.module}/fetchTimePeriod`, capitalize(this.period))
        .then(() => {
          this.onFetchTimePeriod()
          this.$forceUpdate()
        })
    },
    onFetchTimePeriod() {
      if (this.$static.refreshChartEvent) eventBus.$emit(this.$static.refreshChartEvent)

      this.setToday()
    },
    toggleRefreshEvent(action) {
      if (!this.$static.module) return

      eventBus[`$${action}`](`${this.$static.module}/refresh`, this.fetchData)
    },
    setToday() {
      this.selectPoint(this.todayIndex)
    },
    onChartClick(e, item) {
      if (!item[0]) return

      this.selectPoint(item[0]?._index)
    },
    selectPoint(index, datasetIndex = 0) {
      this.selectedDatapoint = this.getDatapointByIndex(index, datasetIndex)
      this.selectedDatapointIndex = index

      if (datasetIndex || datasetIndex === 0) this.selectedDatasetIndex = datasetIndex
      if (this.highlightDatapoint) this.highlightDatapoint(index, datasetIndex)
    },
    getDatapointByIndex(index, datasetIndex = 0) {
      return {
        date: this.chartData?.data?.labels?.[index],
        value: this.chartData?.data?.datasets?.[datasetIndex].data?.[index],
      }
    },
    getDatasetByIndex(index) {
      return this.chartData?.data?.datasets?.[index]
    },
    refreshChartData() {
      this.chartData = this.getChartDataByTimePeriod

      if (this.$static.skipZeroes) this.nullifyZeroes()
    },
    nullifyZeroes() {
      if (!this.chartData) return

      const [dataset] = this.chartData.data.datasets

      dataset.data = dataset.data.map(val => (val === 0 ? null : val))
    },
    moveEndDate(offset) {
      const dateOffset = this.getDateOffset(offset, this.period)

      if (offset > 0 && dateOffset.isAfter(moment.utc().utcOffset(this.getTimezoneOffset).endOf('day'))) {
        this.setToday()
        return
      }

      this.endDate = dateOffset
    },
    getDateOffset(offset, period) {
      return moment.parseZone(this.endDate).add(offset, `${period}s`)
    },
    onVisibleDateRangeChange(visibleDateRange) {
      this.setVisibleDateRange(visibleDateRange)
    },
  },

  computed: {
    ...mapGetters('loading', [
      'getLoadingStatesForActions',
    ]),
    ...mapGetters('user', [
      'getTimezoneOffset',
    ]),
    getChartDataByTimePeriod() {
      if (!this.$static.module) return

      return this.$store.getters[`${this.$static.module}/getChartDataByTimePeriod`](this.period)
    },
    isFetchingChartData() {
      if (!this.$static.module) return

      return this.getLoadingStatesForActions([
        `${this.$static.module}/fetchTimePeriod`,
      ])
    },
    todayIndex() {
      return this.chartData?.data?.labels?.length - 1
    },
    startDate() {
      return this.chartData?.data?.labels?.[0]
    },
    endDate: {
      get() {
        return moment(this.chartData?.data?.labels?.[this.todayIndex]).format('YYYY-MM-DDTHH:mm:ss')
      },
      set(val) {
        this.chartData.to = moment(val, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss')
        // this.fetchData() // uncomment when tracking backend supports changing dates
      },
    },
    todayText() {
      return this.period === 'year' ? 'This month' : 'Today'
    },
    canMoveEndDateForward() {
      return this.getDateOffset(1, 'day')
        .isBefore(moment.utc().utcOffset(this.getTimezoneOffset).endOf('day'))
    },
    canMoveEndDateBackward() {
      /* eslint-disable camelcase */
      return this.getDateOffset(-1, this.period)
        .isAfter(moment.parseZone(this.chartData?.settings?.created_at).startOf('day'))
      /* eslint-enable camelcase */
    },
  },

  static() {
    return {
      module: '',
      refreshChartEvent: '',
      skipZeroes: false,
      periodOptions: ['Week view', 'Month view', 'Year view'],
      periodValues: ['week', 'month', 'year'],
    }
  },
}

export default trackingChart
