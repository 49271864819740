const trackingSettings = {
  created() {
    const promise = this.fetchSettings()
    if (promise) {
      promise.then(() => {
        if (!this.settings) {
          return this.goToSettings()
        }

        if (this.fetchData) return

        this.fetchData()
      })
    }
  },

  computed: {
    settings() {
      return this.$store.state[this.$static.module]?.settings
    },
  },

  methods: {
    fetchSettings() {
      if (!this.$static.module) return Promise.reject()

      return this.$store.dispatch(`${this.$static.module}/fetchSettings`)
    },
  },
}

export default trackingSettings
