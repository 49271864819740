<template>
  <base-main
    class="glucose-tracking"
    data-testid="glucose-tracking"
    :class="{ 'glucose-tracking--hba1c': isHba1c }"
    :loading="$options.loading"
  >
    <container
      first
    >
      <tracking-dashboard
        data-testid="glucose-tracking-dashboard"
        :header-text="i18n('your-blood-glucose')"
        v-bind="{ isFetchingChartData }"
      >
        <template #log-button>
          <base-button
            no-margin
            data-testid="glucose-tracking-open-dialog"
            :link="{ name: 'GlucoseTrackingLog' }"
          >
            {{ i18n('log-blood-glucose') }}
          </base-button>
        </template>

        <template #tracking-datepicker>
          <div class="glucose-tracking__datepicker">
            <tracking-datepicker
              ref="trackingDatepicker"
              :endDate="selectedDate"
              v-bind="{ startDate, minDate, maxDate, period, format }"
              v-model="selectedDate"
              @visibleDateRangeChange="onVisibleDateRangeChange"
            />
          </div>
        </template>

        <template #radio-switcher>
          <radio-switcher
            :ariaLabel="$t('Chart period view')"
            :options="periodOptions"
            :values="$static.periodValues"
            v-model="period"
          />
          <div class="glucose-tracking__today tracking__today">
            <base-button
              bordered
              no-margin
              small
              data-testid="glucose-tracking-set-today"
              @click="setToday"
            >
              {{ todayText }}
            </base-button>
          </div>
        </template>

        <template #secondary-actions>
          <div class="glucose-tracking__units">
            <span class="glucose-tracking__units-label">
              {{ i18n('reading-units') }}
            </span>

            <p class="glucose-tracking__units-values">
              {{ readingUnits }}
            </p>

            <router-link
              class="glucose-tracking__units-link"
              data-testid="glucose-tracking-edit-settings-button"
              :to="{ name: 'GlucoseTrackingSettings' }"
            >
              {{ i18n('tracking-settings') }}
            </router-link>
          </div>
        </template>

        <template #chart>
          <div
            v-if="chartData"
            class="glucose-tracking__chart"
            role="img"
            :aria-label="chartAriaLabel"
          >
            <plugin-unit-chart
              :unit="[
                i18n('blood-glucose-chart-unit', { unit: glucoseUnit }),
                i18n('hba1c-chart-unit', { unit: hba1cUnit }),
              ]"
            >
              <plugin-double-line-chart
                :chartData="[glucoseData, hba1cData]"
                :from="chartData.from"
                :goalPadding="0"
                :onClick="onChartClick"
                :suggestedMax="0"
                :to="chartData.to"
                v-bind="{ period }"
              />
            </plugin-unit-chart>
          </div>
        </template>

        <template #chart-details>
          <div
            class="glucose-tracking__details"
            data-testid="tracking-details-list"
          >
            <tracking-details-tile
              :columns="detailsColumns"
              :values="detailsValues"
            >
              <template #action>
                <base-button
                  no-margin
                  no-min-width
                  data-testid="glucose-tracking-open-edit-dialog"
                  @click="openEdit"
                >
                  {{ $t('Edit') }}
                </base-button>
              </template>
            </tracking-details-tile>
          </div>
        </template>

        <template #info-text>
          <p class="glucose-tracking__info-text-title">
            <strong>Safety notice</strong>
          </p>
          <p class="glucose-tracking__info-text-p">
            If your home blood glucose readings are regularly above 10mmol/l
            discuss this with your healthcare team at your next diabetes review.
          </p>
          <p class="glucose-tracking__info-text-p">
            If your readings are regularly above 20mmol/l or you are experiencing
            symptoms of high blood glucose levels (for example, needing to pass
            urine frequently or feeling very thirsty), stay well hydrated and speak
            to your diabetes healthcare team for advice.
          </p>
        </template>

        <template #chart-accessibility>
          <tracking-details-table
            v-if="anyLoggedValues"
            has-edit-button
            :name="i18n('table-name')"
            :headings="glucoseTableHeadings"
            :items="[...loggedValues.glucose, ...loggedValues.hba1c]"
            :isEditEmit="true"
            @editBy="openEditTable"
            @deleteBy="deleteGlucose"
          />
        </template>
      </tracking-dashboard>
    </container>
  </base-main>
</template>

<script>
  import { cloneDeep, isNil } from 'lodash'
  import moment from 'moment'
  import { mapGetters, mapMutations, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import {
    PluginDoubleLineChart,
    PluginUnitChart,
  } from '@/components/plugin/plugin-charts'
  import RadioSwitcher from '@/components/radio-switcher'
  import {
    TrackingDashboard,
    TrackingDatepicker,
    TrackingDetailsTable,
    TrackingDetailsTile,
  } from '@/components/tracking'

  import { BLOOD_READING_TIMES, DATE_FORMATS, GLUCOSE_UNITS, HBA1C_UNITS } from '@/constants'

  import formatDate from '@/filters/formatDate'

  import cssColorVariableToRgba from '@/helpers/cssColorVariableToRgba'

  import i18n from '@/mixins/i18n'
  import {
    trackingBase,
    trackingChart,
    trackingSettings,
  } from '@/mixins/tracking'
  import unblockPlugin from '@/mixins/unblockPlugin'

  const module = 'glucoseTracking'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.glucose-tracking.glucose-tracking.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginDoubleLineChart,
      PluginUnitChart,
      RadioSwitcher,
      TrackingDashboard,
      TrackingDatepicker,
      TrackingDetailsTable,
      TrackingDetailsTile,
    },

    mixins: [
      i18n,
      trackingBase,
      trackingChart,
      trackingSettings,
      unblockPlugin,
    ],

    created() {
      this.unblockPlugin()
      this.setToday()
      this.fetchData(this.selectedDate)
    },

    watch: {
      day: 'refreshChartData',
      week: 'refreshChartData',
      month: 'refreshChartData',
      period() {
        this.selectedDatapoint = null
      },
      selectedDate() {
        this.fetchData(this.selectedDate)
      },
    },

    data() {
      return {
        period: 'day',
        datepickerModel: null,
        selectedDate: '',
        format: 'YYYY-MM-DDTHH:mm:ssZ',
      }
    },

    methods: {
      ...mapMutations(module, [
        'setVisibleDateRange',
      ]),
      // override
      fetchData(date) {
        this.$store.dispatch(`${module}/fetchTimePeriod`, {
          endDate: date,
          period: this.period,
        }).then(() => {
          this.selectedDatapoint = this.getDatapointByIndex(
            this.selectedDatapointIndex,
            this.selectedDatasetIndex,
          )
        })
      },
      // override
      onChartClick(item) {
        if (!item) return

        this.selectPoint(item._index, item._datasetIndex)
      },
      // override
      getDatapointByIndex(index, datasetIndex) {
        const dataset = this.getDatasetByIndex(datasetIndex)
        const unit = datasetIndex === 0
          ? this.glucoseUnit
          : this.hba1cUnit

        return {
          unit,
          dataset: datasetIndex,
          date: dataset?.label?.[index],
          id: dataset?.id?.[index],
          time: dataset?.time?.[index],
          value: dataset?.value?.[index],
        }
      },
      // override
      getToday() {
        return moment.utc().utcOffset(this.getTimezoneOffset).format(this.format)
      },
      setToday() {
        this.selectedDate = this.getToday()
      },
      getDatasetByIndex(index) {
        return index === 0
          ? this.glucoseData
          : index === 1
            ? this.hba1cData
            : null
      },
      applyUnitToData(field, unit) {
        if (!this.chartData?.[field]) return

        const data = cloneDeep(this.chartData[field])

        data.value = data.value
          .map(val => val[this.settings?.[unit]])
        return data
      },
      openEdit() {
        this.$router.push({
          name: 'GlucoseTrackingLog',
          query: {
            ...this.getDialog(this.selectedDatapoint).props,
          },
        })
      },
      openEditTable(props) {
        this.$router.push({
          name: 'GlucoseTrackingLog',
          query: {
            ...props.dialog.props,
          },
        })
      },
      getDetailsValues(datapointIndex, datasetIndex) {
        const {
          date,
          time,
          unit,
          value,
        } = this.getDatapointByIndex(datapointIndex, datasetIndex)

        if (isNil(value)) return

        const isGlucose = datasetIndex === 0
        const dateFormat = isGlucose
          ? DATE_FORMATS.dateTimeShort
          : DATE_FORMATS.dateShort
        const dateText = formatDate(date, dateFormat)
        const timeText = isGlucose
          ? Object.keys(BLOOD_READING_TIMES)[time - 1]
          : '-'
        const typeText = isGlucose ? 'Blood glucose' : 'HbA1c'
        const valueText = `${value}${unit}`

        return [dateText, timeText, typeText, valueText]
      },
      getDialog(datapoint) {
        const {
          dataset,
          date,
          id,
          time,
          value,
        } = datapoint

        return {
          component: this.$static.logDialog,
          props: {
            date,
            id,
            time,
            readingValue: value,
            type: dataset + 1,
          },
        }
      },
      getDialogByDatapointIndex(datapointIndex, datasetIndex) {
        return this.getDialog(this.getDatapointByIndex(datapointIndex, datasetIndex))
      },
      deleteGlucose(key) {
        this.$router.push({ name: 'GlucoseTrackingDelete', params: { id: key.id } })
      },
    },

    computed: {
      ...mapState(module, [
        'day',
        'week',
        'month',
        'visibleDateRange',
      ]),
      ...mapGetters('colors', [
        'colors',
      ]),
      ...mapGetters('user', [
        'getTimezoneOffset',
      ]),
      detailsDataValue() {
        return this.selectedDatapoint.value || this.i18n('no-reading-logged')
      },
      glucoseUnit() {
        /* eslint-disable-next-line camelcase */
        return GLUCOSE_UNITS[this.settings?.glucose_units]
      },
      hba1cUnit() {
        /* eslint-disable-next-line camelcase */
        return HBA1C_UNITS[this.settings?.hba1c_units]
      },
      glucoseData() {
        return {
          ...this.applyUnitToData('glucose', 'glucose_units'),
          backgroundColor: this.glucoseBackgroundColors,
          borderColor: this.glucoseBackgroundColors,
        }
      },
      hba1cData() {
        return {
          ...this.applyUnitToData('hba1c', 'hba1c_units'),
          backgroundColor: this.hba1cBackgroundColors,
        }
      },
      glucoseBackgroundColors() {
        return this.chartData?.glucose?.time?.map(val => this.$static.glucoseColors[val])
      },
      hba1cBackgroundColors() {
        return this.chartData?.hba1c?.label?.map(() => this.colors['color-chart-hba1c'])
      },
      readingUnits() {
        return `${this.i18n('hba1c-unit', { unit: this.hba1cUnit })}
          ${this.i18n('blood-glucose-unit', { unit: this.glucoseUnit })}`
      },
      startDate() {
        if (!this.chartData?.from) return

        return moment.parseZone(this.chartData.from)
      },
      detailsColumns() {
        if (!this.selectedDatasetIndex && this.selectedDatasetIndex !== 0) return []

        return this.selectedDatasetIndex === 0
          ? this.bloodGlucoseDetails
          : this.selectedDatasetIndex === 1
            ? this.hba1cDetails
            : []
      },
      detailsValues() {
        if (!this.selectedDatapointIndex && this.selectedDatapointIndex !== 0) return []

        return this.getDetailsValues(this.selectedDatapointIndex, this.selectedDatasetIndex)
      },
      detailsValuesKey() {
        return this.detailsValues?.join('')
      },
      isHba1c() {
        return this.selectedDatapoint?.dataset === 1
      },
      minDate() {
        /* eslint-disable-next-line camelcase */
        const createdAt = this.chartData?.settings?.created_at

        if (!createdAt) return

        return moment(createdAt).format('YYYY-MM-DDT00:00:00Z')
      },
      maxDate() {
        return moment().utcOffset(this.getTimezoneOffset).format('YYYY-MM-DDT23:59:59Z')
      },
      loggedValues() {
        const { glucose, hba1c } = this.chartData || {}

        return {
          glucose: Object.values(glucose?.id || [])
            .map((el, index) => ({
              columns: this.getDetailsValues(index, 0),
              dialog: this.getDialogByDatapointIndex(index, 0),
              id: el,
            })),
          hba1c: Object.values(hba1c?.id || [])
            .map((el, index) => ({
              columns: this.getDetailsValues(index, 1),
              dialog: this.getDialogByDatapointIndex(index, 1),
              id: el,
            })),
        }
      },
      translatedPeriod() {
        return this.$t(this.period)
      },
      periodOptions() {
        return this.i18n(['day-view', 'week-view', 'month-view'])
      },
      bloodGlucoseDetails() {
        return this.i18n(['selected-date', 'reading-taken', 'type-of-reading', 'logged-reading'])
      },
      hba1cDetails() {
        return this.i18n(['selected-date', 'reading-taken', 'type-of-reading', 'logged-reading'])
      },
      anyLoggedValues() {
        return this.loggedValues?.glucose?.length || this.loggedValues?.hba1c?.length
      },
      glucoseTableHeadings() {
        return [...this.bloodGlucoseDetails, '']
      },
      chartAriaLabel() {
        return `${this.i18n('table-name')} ${this.visibleDateRange || ''}`
      },
    },

    loading: [
      `${module}/fetchSettings`,
      `${module}/fetchTimePeriod`,
    ],

    static() {
      return {
        module,
        logDialog: 'GlucoseTrackingLog',
        refreshChartEvent: 'lineChart/refreshChart',
        settingsComponent: 'GlucoseTrackingSettings',
        periodValues: ['day', 'week', 'month'],
        glucoseColors: {
          1: cssColorVariableToRgba('glucose-before-food'),
          2: cssColorVariableToRgba('glucose-after-food'),
          3: cssColorVariableToRgba('glucose-before-bed'),
          4: cssColorVariableToRgba('glucose-after-bed'),
        },
      }
    },

    slug: 'views.plugins.glucose-tracking.glucose-tracking',
  }
</script>

<style lang="scss">
  $block: 'glucose-tracking';

  .#{$block} {
    max-width: none;
    padding: 0 0 1rem;
    &__layout {
      @include min-lg {
        display: grid;
        grid-column-gap: 3rem;
        grid-template-columns: 27rem 1fr;
      }
      & > .simple-tile__main {
        @include min-lg {
          padding: 3.5rem;
        }
      }
    }
    &__goals {
      @include min-lg {
        width: 27rem;
        margin: 0 3rem 0 0;
      }
    }
    &__goal {
      margin: 0 0 2rem;
      .modular-tile {
        &__main {
          @include max-lg {
            padding: 2rem;
          }
        }
        &__header-row {
          @include max-lg {
            align-items: flex-start;
            min-height: 3rem;
          }
        }
        &__top-image-row {
          @include max-lg {
            display: none;
          }
        }
      }
      .button {
        margin: 1rem 0 0;
        @include min-lg {
          margin: 0;
          padding: 2px 1.8rem 0;
        }
      }
    }
    &__datepicker {
      display: flex;
      justify-content: center;
      align-items: center;
      .input-group {
        max-width: 0;
        &__icon {
          width: auto;
        }
        &__input {
          max-width: 100%;
        }
      }
    }
    &__arrow-button {
      width: 2.4rem;
      height: 2.4rem;
    }
    &__arrow {
      grid-area: button-prev;
      width: 1.4rem;
      height: auto;
      &--right {
        grid-area: button-next;
        transform: rotate(180deg);
      }
    }
    &__today {
      display: none;
      text-align: right;
      @include min-lg {
        display: block;
      }
    }
    &__units {
      padding: 2rem 0 0;
      font-size: 1.2rem;
      text-align: center;
      @include min-lg {
        padding: 0;
        text-align: right;
      }
    }
    &__units-label {
      font-weight: 600;
      display: flex;
      flex-direction: column;
    }
    &__units-values {
      white-space: pre-line;
    }
    &__units-link {
      padding: 0;
      color: color(dark-grey);
      font-weight: 400;
      text-decoration: underline;
    }
    &__chart {
      display: block;
      border: solid 1px color(primary);
      border-top: 0;
      .plugin-unit-chart {
        margin: 0 0.5rem;
      }
    }
    &__info-text-title {
      margin: 2rem 0 0;
    }
    &__info-text-p {
      margin: 1rem 0 0;
    }
    &__details {
      margin: 2rem 0 0;
    }
    &--hba1c {
      .tracking-details-tile .simple-tile {
        border-color: color('chart5');
      }
    }
    .container {
      @include max-sm {
        padding: 1rem;
      }
      @include min-lg {
        width: 120rem;
      }
    }
    .plugin-unit-chart {
      &__unit-layout {
        margin: 3rem 0 0;
      }
    }
  }
</style>
