<template>
  <base-main
    class="step-tracking"
    data-testid="step-tracking"
    :loading="$options.loading"
  >
    <container first>
      <div class="step-tracking__layout">
        <tracking-goals
          :addButtonText="i18n('add-button')"
          :editButtonText="i18n('edit-button')"
          :goalTypeId="$static.goalCategory"
          :imageUrl="requireImage('modular-tile/top-tracking-goal.jpg')"
        />

        <tracking-dashboard
          :header-text="i18n('your-steps')"
          data-testid="step-tracking-dashboard"
          v-bind="{ isFetchingChartData }"
        >
          <template #log-button>
            <base-button
              no-margin
              data-testid="step-tracking-open-log-dialog"
              @click="clickLog()"
            >
              {{ i18n('log-steps') }}
            </base-button>
          </template>

          <template #tracking-datepicker>
            <tracking-datepicker
              read-only
              v-bind="{startDate, endDate, period}"
              v-model="endDate"
              @visibleDateRangeChange="onVisibleDateRangeChange"
            />
          </template>

          <template #radio-switcher>
            <radio-switcher
              :ariaLabel="$t('Chart period view')"
              :options="$static.periodOptions"
              :values="$static.periodValues"
              v-model="period"
            />
            <div class="step-tracking__today tracking__today">
              <base-button
                bordered
                no-margin
                small
                data-testid="step-tracking-set-today"
                @click="setToday"
              >
                {{ todayText }}
              </base-button>
            </div>
          </template>

          <template #secondary-actions>
            <div class="step-tracking__tracking">
              <span class="step-tracking__tracking-label">
                {{ i18n('data-entry') }}
              </span>
              {{ trackingMethod }}
              <router-link
                class="step-tracking__tracking-button"
                data-testid="step-tracking-edit-settings-button"
                :to="{ name: 'StepTrackingSettings' }"
              >
                {{ i18n('tracking-settings') }}
              </router-link>
            </div>
          </template>

          <template #chart>
            <div
              v-if="chartData"
              class="step-tracking__chart"
              role="img"
              :aria-label="chartAriaLabel"
            >
              <plugin-unit-chart
                :unit="$t('Steps')"
              >
                <plugin-bar-chart
                  :goalPadding="1000"
                  :onClick="onChartClick"
                  :suggestedMax="1000"
                  :type="'step'"
                  v-bind="{ annotationLabel, chartData, goal, period, selectedDatapointIndex }"
                />
              </plugin-unit-chart>
            </div>
          </template>

          <template #chart-details>
            <div class="step-tracking__details">
              <tracking-details-tile
                :columns="detailsColumns"
                :values="detailsValues"
              />
            </div>
          </template>

          <template #chart-accessibility>
             <tracking-details-table
              v-if="loggedSteps && hasloggedSteps"
              :name="i18n('table-name')"
              :headings="detailsColumns"
              :hasEditButton="period !== 'year'"
              :items="loggedSteps"
              @edit="(key) => editStep(key)"
              @delete="(key) => deleteStep(key)"
            />
          </template>
        </tracking-dashboard>
      </div>
    </container>
  </base-main>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapMutations, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import {
    PluginBarChart,
    PluginUnitChart,
  } from '@/components/plugin/plugin-charts'
  import RadioSwitcher from '@/components/radio-switcher'
  import {
    TrackingDashboard,
    TrackingDatepicker,
    TrackingDetailsTable,
    TrackingDetailsTile,
    TrackingGoals,
  } from '@/components/tracking'

  import {
    DATE_FORMATS,
    GOAL_CATEGORIES,
  } from '@/constants'

  import getCssColorVariable from '@/helpers/getCssColorVariable'

  import i18n from '@/mixins/i18n'
  import requireImage from '@/mixins/requireImage'
  import {
    trackingBase,
    trackingChart,
    trackingGoal,
    trackingSettings,
  } from '@/mixins/tracking'
  import unblockPlugin from '@/mixins/unblockPlugin'

  import eventBus from '@/tools/event-bus'

  const module = 'stepTracking'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.step-tracking.step-tracking.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginBarChart,
      PluginUnitChart,
      RadioSwitcher,
      TrackingDashboard,
      TrackingDatepicker,
      TrackingDetailsTable,
      TrackingDetailsTile,
      TrackingGoals,
    },

    mixins: [
      i18n,
      requireImage,
      trackingBase,
      trackingChart,
      trackingGoal,
      trackingSettings,
      unblockPlugin,
    ],

    created() {
      this.unblockPlugin()
    },

    watch: {
      week: 'refreshChartData',
      month: 'refreshChartData',
      year: 'refreshChartData',
    },

    methods: {
      ...mapActions(module, [
        'stepTracking/fetchSettings',
        'stepTracking/saveSettings',
      ]),
      ...mapMutations(module, [
        'setVisibleDateRange',
      ]),
      createDiagonalPattern(color) {
        const shape = document.createElement('canvas')
        shape.width = 10
        shape.height = 10
        const c = shape.getContext('2d')
        c.fillStyle = color
        c.fillRect(0, 0, 100, 100)

        c.strokeStyle = 'black'
        c.beginPath()
        c.moveTo(2, 0)
        c.lineTo(10, 8)
        c.stroke()
        c.beginPath()
        c.moveTo(0, 8)
        c.lineTo(2, 10)
        c.stroke()
        return c.createPattern(shape, 'repeat')
      },
      highlightDatapoint(index) {
        const [dataset] = this.chartData.data.datasets
        const colorPrimary = getCssColorVariable('primary')
        const colorDarkPrimary = getCssColorVariable('dark-primary')
        const colors = dataset.backgroundColor
          .map((val, i) => (i === index
          ? this.createDiagonalPattern(colorPrimary) : colorDarkPrimary))

        dataset.backgroundColor = colors
        dataset.hoverBackgroundColor = colors
        eventBus.$emit(this.$static.refreshChartEvent)
      },
      getFormatedDate(date) {
        return moment(date)
          .format(this.period === 'year'
            ? DATE_FORMATS.monthYear
            : DATE_FORMATS.date)
      },
      getStepsValue(steps) {
        return steps || this.i18n('no-steps')
      },
      editStep(key) {
        this.$router.push({ name: 'StepTrackingLog', params: { id: `${moment(key).format('YYYY-MM-D')}` } })
      },
      deleteStep(key) {
        this.$router.push({ name: 'StepTrackingDelete', params: { id: `${moment(key).format('YYYY-MM-D')}` } })
      },
      fetchSettings() {
        this.$store.dispatch(`${this.$static.module}/fetchSettings`).then(() => {
          if (!this.settings) {
            this.$store.dispatch(`${this.$static.module}/saveSettings`, {
              google_code: false,
              method_step_tracking: 'manual',
              steps_goal: '0',
              tracking_system: '',
            }).then(() => {
              this.doFetchData()
            })
          } else {
            this.doFetchData()
          }
        })
      },

      doFetchData() {
        if (this.fetchData) return
        this.fetchData()
      },
      clickLog(type) {
        this.$router.push({ name: 'StepTrackingLog', params: { type } })
      },
    },

    computed: {
      ...mapState(module, [
        'week',
        'month',
        'year',
        'visibleDateRange',
      ]),
      isTrackingAutomatic() {
        /* eslint-disable-next-line camelcase */
        return this.settings?.method_step_tracking == 'automat'
      },
      trackingMethod() {
        if (!this.isTrackingAutomatic) return this.i18n('manual')

        /* eslint-disable-next-line camelcase */
        const system = this.settings?.tracking_system == 'apple'
                ? this.$t('Apple Healthkit')
                : this.$t('Google Fit')

        const account = this.settings?.account
                ? `${this.i18n('account')} ${this.settings.account}`
                : this.i18n('no-account')

        return `${this.i18n('automatic')} ${system} ${account}`
      },
      detailsDataValue() {
        return this.getStepsValue(this.selectedDatapoint.value)
      },
      goal() {
        return parseFloat(this.goalContent)
      },
      detailsColumns() {
        if (!this.selectedDatapoint) return

        return ['Selected date', 'Logged steps']
      },
      detailsValues() {
        if (!this.selectedDatapoint) return

        const dateText = this.getFormatedDate(this.selectedDatapoint?.date)

        return [dateText, this.detailsDataValue]
      },
      todayText() {
        return this.datepickerView === 'year' ? this.$t('This month') : this.$t('Today')
      },
      annotationLabel() {
        return this.goal
          ? this.$t('Daily steps goal - {stepGoal} steps', { stepGoal: this.goal })
          : undefined
      },
      loggedSteps() {
        return this.chartData.data.labels.map((label, index) => {
          const value = this.chartData.data.datasets[0].data[index]
          if (value === 0) return { columns: [] }
          return { columns: [
            this.getFormatedDate(label),
            this.getStepsValue(value),
          ] }
        })
      },
      hasloggedSteps() {
        return this.loggedSteps.findIndex(({ columns }) => columns.length !== 0) !== -1
      },
      chartAriaLabel() {
        return `${this.i18n('table-name')} ${this.visibleDateRange || ''}`
      },
    },

    slug: 'views.plugins.step-tracking.step-tracking',

    static() {
      return {
        module,
        goalCategory: GOAL_CATEGORIES.stepTracking,
        goalTemplateSlug: 'daily_steps_goal',
        refreshChartEvent: 'roundedBarChart/refreshChart',
        settingsComponent: 'StepTrackingSettings',
      }
    },

    loading: [
      'goals/fetchGoals',
      'goals/fetchPlugin',
      'stepTracking/fetchTimePeriod',
    ],
  }
</script>

<style lang="scss">
  $block: 'step-tracking';

  .#{$block} {
    max-width: none;
    &__layout {
      @include min-lg {
        display: grid;
        grid-column-gap: 3rem;
        grid-template-columns: 27rem 1fr;
      }
      & > .simple-tile__main {
        @include min-lg {
          padding: 3.5rem;
        }
      }
    }
    &__goals {
      @include min-lg {
        width: 27rem;
        margin: 0 3rem 0 0;
      }
    }
    &__goal {
      margin: 0 0 2rem;
      .modular-tile {
        &__main {
          @include max-lg {
            padding: 2rem;
          }
        }
        &__header-row {
          @include max-lg {
            align-items: flex-start;
            min-height: 3rem;
          }
        }
        &__top-image-row {
          @include max-lg {
            display: none;
          }
        }
      }
      .button {
        margin: 1rem 0 0;
        @include min-lg {
          margin: 0;
          padding: 2px 1.8rem 0;
        }
      }
    }
    &__arrow-button {
      width: 2.4rem;
      height: 2.4rem;
      @include min-lg {
        width: 3.4rem;
        height: 3.4rem;
      }
    }
    &__arrow {
      grid-area: button-prev;
      width: 1.6rem;
      height: auto;
      &--right {
        grid-area: button-next;
        transform: rotate(180deg);
      }
    }
    &__today {
      display: none;
      text-align: right;
      @include min-lg {
        display: block;
      }
    }
    &__tracking {
      padding: 2rem 0 0;
      font-size: 1.2rem;
      text-align: center;
      @include min-lg {
        padding: 0;
        text-align: right;
      }
    }
    &__tracking-label {
      font-weight: 600;
    }
    &__tracking-button {
      padding: 0;
      color: color(dark-grey);
      font-weight: 400;
      text-decoration: underline;
    }
    &__chart {
      display: block;
      border: solid 1px color(primary);
      border-top: 0;
    }
    &__details {
      margin: 3rem 0 0;
    }
    .container {
      @include max-sm {
        padding: 1rem;
      }
      @include min-lg {
        width: 120rem;
      }
    }
    .plugin-unit-chart {
      &__unit-layout {
        margin: 2rem 0 0;
      }
    }
  }
</style>
